import { useEffect, useState } from "react";
import { apiUrl } from "../api";
interface Location {
  first: string;
  second: string;
}
interface Terminal {
  id: number;
  name: string;
  mada_pkg: string;
  merchant: string;
  isOnline: boolean;
  location: Location | null;
}
function Terminals() {
  const [items, setItems] = useState<Terminal[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetch(`${apiUrl}/terminals?page=${currentPage - 1}&size=${itemsPerPage}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Include the auth token in the Authorization header
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setItems(data.content);
        setTotalPages(data.totalPages); //
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, [currentPage, itemsPerPage]);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  return (
    <div className="container mx-auto">
      <h1 className="text-2xl font-bold mb-4">Terminals</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 ">
          <thead className="bg-gray-50">
            <tr>
              <th className="py-2 border-b">Status</th>
              <th className="py-2 border-b">Terminal ID</th>
              <th className=" border-b">Name</th>
              <th className=" border-b">Merchant</th>
              <th className=" border-b">Mada App</th>
              <th className=" border-b">Location</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <tr key={item.id}>
                <td className="py-2 border-b text-center">
                {item.isOnline ? (
              <svg viewBox="0 0 24 24" className="w-6 h-6 inline">                
                <circle cx="12" cy="12" r="10" fill="green" />
              </svg>
            ) : (
              <svg viewBox="0 0 24 24" className="w-6 h-6 inline">
                <circle cx="12" cy="12" r="10" fill="red" />
              </svg>
            )}
                </td>
                <td className="py-2 border-b text-center">{item.id}</td>
                <td className="py-2 border-b text-center">{item.name} </td>

                <td className="py-2 border-b text-center">
                  {item.merchant}
                </td>
                <td className="py-2 border-b text-center">
                  {item.mada_pkg}
                </td>
                <td className="py-2 border-b text-center text-blue-500">
                  {item.location && item.location?.first !== "null" && 
                  <a href={`https://maps.google.com/maps?q=${item.location.first},${item.location.second}`} target="_blank">
                  show on map
                  </a>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Pagination Controls */}
        {totalPages > 1 && (
          <div className="flex justify-center mt-4">
            <nav>
              <ul className="flex list-none">
                {Array.from({ length: totalPages }, (_, index) => (
                  <li key={index} className="mx-1">
                    <button
                      onClick={() => paginate(index + 1)}
                      className={`px-3 py-1 border ${
                        currentPage === index + 1
                          ? "bg-blue-500 text-white"
                          : "bg-white text-blue-500"
                      } hover:bg-blue-500 hover:text-white`}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        )}
      </div>
    </div>
  );
}
export default Terminals;
