// src/components/Layout.tsx
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import TransactionDialog from "./TransactionDialog";
import { useWebSocket } from "../useWebsocket";
import { useAuth } from "../useAuth";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [status, setStatus] = useState<"approved" | "declined" | "canceled">(
    "approved"
  );
  const [transactionId, setTransactionId] = useState<string>("");
  const { connect, message, isConnected, disconnect } = useWebSocket();
  const { logout } = useAuth();

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };
  const handleCloseDialog = () => {
    setShowDialog(false);
  };
  const handleShowDialog = (status: "approved" | "declined" | "canceled") => {
    setStatus(status);
    setShowDialog(true);
  };
  useEffect(() => {
    if (message.substring(0, 3) === "PAY") {
      const st = message.substring(3, 4);
      setTransactionId(message.substring(4, message.length));
      if (st === "0") handleShowDialog("declined");
      else if (st === "1") handleShowDialog("approved");
    }
  }, [message]);

  useEffect(() => {
    connect();
  }, [connect]);

  return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 w-64  p-6 transform transition-transform duration-300 z-10
          ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} `}
      >
        <h2 className="text-xl font-semibold mb-6">Admin Panel</h2>
        <nav>
          <ul>
            {/* <li className="mb-4">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="mb-4">
              <Link to="/merchants">Merchants</Link>
            </li> */}
            <li className="mb-4">
              <Link to="/">Cashier</Link>
            </li>
            <li className="mb-4">
              <Link to="/terminals">Terminals</Link>
            </li>
            <li className="mb-4">
              <Link to="/transactions">Transactions</Link>
            </li>
          </ul>
        </nav>
      </div>

      <div
        className={`flex-1 bg-gray-50 transition-all duration-300
          ${isSidebarOpen ? "ml-64" : "ml-2"}`}
      >
        {/* top bar */}
        <div className={`flex  flex-grow gap-3 justify-end p-2 bg-gray-200 `}>
          {!isConnected && (
            <button
              className="hover:text-blue-500 hover:border-b border-blue-500 "
              onClick={connect}
            >
              connect
            </button>
          )}
          <button
            className="hover:text-blue-500 hover:border-b border-blue-500"
            onClick={() => {
              disconnect();
              logout();
            }}
          >
            logout
          </button>

          <div
            data-tooltip-id="my-tooltip"
            data-tooltip-content="platform connection status"
          >
            {isConnected ? (
              <svg viewBox="0 0 24 24" className="w-6 h-6">
                {" "}
                <circle cx="12" cy="12" r="10" fill="green" />{" "}
              </svg>
            ) : (
              <svg viewBox="0 0 24 24" className="w-6 h-6">
                {" "}
                <circle cx="12" cy="12" r="10" fill="red" />{" "}
              </svg>
            )}
          </div>
          <ReactTooltip id="my-tooltip" />
        </div>
        <div className="pl-10 pt-2">
          {/* Toggle Button */}
          <button
            onClick={toggleSidebar}
            className={`fixed top-12 z-2 bg-white text-primary p-1 rounded-full transition-transform duration-300 
          ${isSidebarOpen ? "left-[260px]" : "left-4"} `}
          >
            {isSidebarOpen ? (
              <ChevronLeftIcon className="w-6 h-6" /> // Left arrow when sidebar is open
            ) : (
              <ChevronRightIcon className="w-6 h-6" /> // Right arrow when sidebar is hidden
            )}
          </button>
          <div>
            {/* Main Content */}
            <div>{children}</div>
          </div>
        </div>
      </div>
     
      {showDialog && (
        <TransactionDialog
          transactionId={transactionId}
          status={status}
          onClose={handleCloseDialog}
        />
      )}
    </div>
  );
};

export default Layout;
