import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../useAuth';

interface ProtectedRouteProps {
  element: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { token } = useAuth();
 

  return token !=null ? <>{element}</> : <Navigate to="/home" />;
  
};

export default ProtectedRoute;
