
import React, { createContext, useState, ReactNode } from 'react';

// Define the structure for the context data
export interface AuthContextType {
  token: string | null;
  login: (token: string) => void;
  logout: () => void;
  getToken: () => string | null;
}

// Create the context
export const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Define the provider component
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('accessToken'));
  
  // Function to retrieve token from localStorage (or sessionStorage)
  const getToken = (): string | null => {
    return localStorage.getItem('accessToken');
  };

  // Effect to check authentication state on component mount
  // useEffect(() => {
  //   const token = getToken();
  //   console.log("token: ", token)
  //   if (token) {
  //     setToken(true);  // If a token exists, user is authenticated
  //   }
  //   console.log("AuthContext", token)
  // }, [token]);

  // Login function: save token to localStorage and set isAuthenticated to true
  const login = (token: string) => {
    localStorage.setItem('accessToken', token);
    setToken(token);
  };

  // Logout function: remove token and set isAuthenticated to false
  const logout = () => {
    localStorage.removeItem('accessToken');
    setToken(null);
  };

  // Provide state and actions to the context consumers
  return (
    <AuthContext.Provider value={{  token, login, logout, getToken }}>
      {children}
    </AuthContext.Provider>
  );
};


