// src/App.tsx
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import Login from "./pages/Login";
import Layout from "./components/Layout";
import Cashier from "./pages/Cashier";
// import Dashboard from "./pages/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import Transactions from "./pages/Transactions";
import Terminals from "./pages/Terminals";
import { WebSocketProvider } from "./WebSocketContext";
import { ToastContainer } from "react-toastify";

const App = () => {
  // check if user is authenticated
  const isAutghenticated = localStorage.getItem("accessToken") != null;

  return (
    <AuthProvider>
      <WebSocketProvider>
        <Router>
          <Routes>
            <Route path="/cuppertino/remalcomplex/login" element={isAutghenticated ? <Navigate to="/" /> : <Login />} />

            <Route
              path="/home"
              Component={() => {
                window.location.href = "/home.html";
                return null;
              }}
            />

            <Route
              path="/"
              element={
                <ProtectedRoute
                  element={
                    <Layout>
                      <Cashier />
                    </Layout>
                  }
                />
              }
            />
            <Route
              path="/terminals"
              element={
                <ProtectedRoute
                  element={
                    <Layout>
                      <Terminals />
                    </Layout>
                  }
                />
              }
            />
            <Route
              path="/transactions"
              element={
                <ProtectedRoute
                  element={
                    <Layout>
                      <Transactions />
                    </Layout>
                  }
                />
              }
            />
          </Routes>
        </Router>
      </WebSocketProvider>
      <ToastContainer />
    </AuthProvider>
  );
};

export default App;
